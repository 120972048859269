var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { List, ListSubheader } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { ListContentListItemComponent } from "./ListContent-ListItem.component";
import { EXTERNAL_LINKS } from "../../shared/infrastructure/router/routes";
export function LinkPageLinkListComponent() {
    var t = useTranslation(["ExternLinks"]).t;
    var _a = __read(useState([]), 2), linkData = _a[0], setLinkData = _a[1];
    useEffect(function () {
        var apiEndpoint = "".concat(EXTERNAL_LINKS, "?locale=").concat(i18next.language);
        callApiService(apiEndpoint)
            .then(function (response) {
            console.log("API Response:", response.data);
            if (response.data && response.data.data) {
                var extractedLinks = response.data.data.map(function (item) { return item.attributes; });
                setLinkData(extractedLinks);
            }
            return null;
        })
            .catch(function (error) {
            console.error("Error fetching data: ", error);
        });
    }, [i18next.language]);
    var groupByCategory = function (links) {
        var grouped = links.reduce(function (acc, link) {
            if (!acc[link.category])
                acc[link.category] = [];
            acc[link.category].push(link);
            return acc;
        }, {});
        return grouped;
    };
    var groupedLinks = groupByCategory(linkData);
    var categoryOrder = [
        "ONLINE_TOOLS",
        "REPORTS",
        "ORGANIZATION_AND_INSTITUTES",
        "LEGAL_REGULATION",
    ];
    return (React.createElement("div", null, categoryOrder.map(function (category) {
        var linksForCategory = groupedLinks[category];
        if (!linksForCategory || linksForCategory.length === 0) {
            return null;
        }
        var sortedLinks = linksForCategory
            .slice()
            .sort(function (a, b) { return b.priority - a.priority; });
        return (React.createElement(List, { key: category },
            React.createElement(ListSubheader, null, t("".concat(category), category)),
            sortedLinks.map(function (link, index) { return (React.createElement(ListContentListItemComponent, { key: "Link".concat(index), link: link.link, text: link.name })); })));
    })));
}
