import React from "react";
import { LogoCardComponent } from "../../shared/components/logo_card/LogoCard.component";
import { useWelcomePageComponent } from "./welcomeUseCase";
import Markdown from "markdown-to-jsx";
import { PageLayoutComponent } from "../../shared/components/layout/PageLayoutComponent";
export function WelcomeMainComponent() {
    var model = useWelcomePageComponent(null).model;
    return (React.createElement(PageLayoutComponent, null,
        React.createElement("div", { style: {
                maxHeight: "95vh",
                overflowY: "auto",
                padding: "1rem",
            } },
            React.createElement(LogoCardComponent, { title: "ZooNotify", subtitle: model.subtitle, text: React.createElement(Markdown, null, model.content) }))));
}
