import React from "react";
import { Typography } from "@mui/material";
import { formatMicroorganismNameArray } from "./utils";
var FormattedMicroorganismName = function (_a) {
    var microName = _a.microName;
    var words = formatMicroorganismNameArray(microName);
    return (React.createElement(Typography, { component: "span" }, words.map(function (wordObj, index) { return (React.createElement(React.Fragment, { key: index },
        wordObj.italic ? React.createElement("i", null, wordObj.text) : wordObj.text,
        " ")); })));
};
export { FormattedMicroorganismName };
