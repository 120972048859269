var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { DataGridControls } from "./DataGridControls";
import { ZNAccordion } from "../../shared/components/accordion/ZNAccordion";
import JSZip from "jszip";
import { usePrevalenceFilters } from "./PrevalenceDataContext";
import { PrevalenceChart } from "./PrevalenceChart";
import { createTheme, ThemeProvider } from "@mui/material/styles";
var italicWords = [
    "Salmonella",
    "coli",
    "E.",
    "Bacillus",
    "cereus",
    "monocytogenes",
    "Clostridioides",
    "difficile",
    "Yersinia",
    "Listeria",
    "enterocolitica",
    "Vibrio",
    "Baylisascaris",
    "procyonis",
    "Echinococcus",
    "Campylobacter",
];
var GERMAN_README = "\nDieser ZooNotify-Daten-Download enth\u00E4lt diese README-Datei, zwei CSV-Dateien und eine zus\u00E4tzliche Textdatei. Der Inhalt und die Verwendung dieser Dateien wird im Folgenden erl\u00E4utert.\n\nprevalence_data_dot.csv: f\u00FCr Software mit deutschen Spracheinstellungen \nDiese Datei enth\u00E4lt punktgetrennte Daten, die das korrekte Zahlenformat in Softwareprogrammen (wie Microsoft Office Excel oder LibreOffice Sheets) mit deutschen Spracheinstellungen unterst\u00FCtzen. Diese Datei kann in Programmen ge\u00F6ffnet werden, die Kommas als Dezimaltrennzeichen verwenden. \n\nprevalence_data_comma.csv: f\u00FCr Software mit englischen Spracheinstellungen \nDiese Datei enth\u00E4lt kommagetrennte Daten, die das korrekte Zahlenformat in Softwareprogrammen (wie Microsoft Office Excel oder LibreOffice Sheets) mit englischen Spracheinstellungen unterst\u00FCtzen. Diese Datei kann in Programmen ge\u00F6ffnet werden, die Punkte als Dezimaltrennzeichen verwenden. \n\nsearch_parameters.txt\nIn der Textdatei search_parameters.txt finden Sie Informationen zu den Parametern, die Sie im Suchmen\u00FC von ZooNotify ausgew\u00E4hlt haben, bevor Sie die Daten heruntergeladen haben. Die heruntergeladenen CSV-Dateien enthalten nur Daten, die mit den in der Datei search_parameters.txt angegebenen Suchkriterien \u00FCbereinstimmen.\n";
var ENGLISH_README = "\nThis ZooNotify data download contains this README-file, two CSV-files and one additional text file. The content and use of these files is explained below.\n\nprevalence_data_dot.csv: for software with German language settings\nThis file contains dot-separated data, which supports the correct format of numbers in software programmes (like Microsoft Office Excel or LibreOffice Sheets) with German language settings. This file can be opened in software which use commas as decimal separators.\n\nprevalence_data_comma.csv: for software with English language settings\nThis file contains comma-separated data, which supports the correct format of numbers in software programmes (like Microsoft Office Excel or LibreOffice Sheets) with English language settings. This file can be opened in software which use dots as decimal separators.\n\nsearch_parameters.txt\nIn the text file search_parameters.txt you will find information about the parameters you have selected in the search menu on ZooNotify before you downloaded the data. The downloaded CSV-files only contain data that matches the search criteria specified in the search_parameters.txt file.\n";
var formatMicroorganismName = function (microName) {
    if (!microName) {
        console.warn("Received null or undefined microorganism name");
        return React.createElement(React.Fragment, null);
    }
    var words = microName
        .split(/(\s+|-)/)
        .filter(function (part) { return part.trim().length > 0; });
    return words
        .map(function (word, index) {
        var italic = italicWords.some(function (italicWord) {
            return word.toLowerCase().includes(italicWord.toLowerCase());
        });
        return italic ? (React.createElement("i", { key: index }, word)) : (React.createElement("span", { key: index }, word));
    })
        .reduce(function (prev, curr) { return (React.createElement(React.Fragment, null,
        prev,
        prev ? " " : "",
        curr)); }, React.createElement(React.Fragment, null));
};
var localTooltipTheme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#f0f0f0",
                    color: "#000000",
                    fontSize: "0.810rem",
                    border: "1px solid #000000",
                },
                arrow: {
                    color: "#000000",
                },
            },
        },
    },
});
var PrevalenceDataGrid = function (_a) {
    var prevalenceData = _a.prevalenceData, loading = _a.loading, language = _a.language;
    var t = useTranslation(["PrevalencePage"]).t;
    var _b = __read(useState(null), 2), downloadUrl = _b[0], setDownloadUrl = _b[1];
    var _c = __read(useState(""), 2), filename = _c[0], setFilename = _c[1];
    var theme = useTheme();
    var _d = usePrevalenceFilters(), searchParameters = _d.searchParameters, prevalenceUpdateDate = _d.prevalenceUpdateDate;
    var getFormattedTimestamp = function () {
        var date = new Date();
        return date.toISOString().replace(/[:.]/g, "-");
    };
    var formatNumber = function (value, decimalSeparator) {
        return value.toString().replace(".", decimalSeparator);
    };
    var createCSVContent = function (data, decimalSeparator) {
        var csvRows = [];
        var headers = [
            "samplingYear",
            "microorganism",
            "sampleOrigin",
            "samplingStage",
            "matrix",
            "numberOfSamples",
            "numberOfPositive",
            "percentageOfPositive",
            "ciMin",
            "ciMax",
        ];
        var headerTranslationKeys = {
            id: "ID",
            samplingYear: "SAMPLING_YEAR",
            numberOfSamples: "NUMBER_OF_SAMPLES",
            numberOfPositive: "NUMBER_OF_POSITIVE",
            percentageOfPositive: "PERCENTAGE_OF_POSITIVE",
            ciMin: "CIMIN",
            ciMax: "CIMAX",
            matrix: "MATRIX",
            matrixGroup: "MATRIX_GROUP",
            samplingStage: "SAMPLING_STAGE",
            sampleOrigin: "SAMPLE_ORIGIN",
            microorganism: "MICROORGANISM",
            superCategorySampleOrigin: "SUPER_CATEGORY_SAMPLE_ORIGIN",
        };
        csvRows.push("\uFEFF" +
            headers
                .map(function (header) {
                return t(headerTranslationKeys[header] ||
                    "MISSING_TRANSLATION");
            })
                .join(";"));
        data.forEach(function (row) {
            var values = headers.map(function (header) {
                var value = row[header];
                if (typeof value === "number") {
                    return formatNumber(value, decimalSeparator);
                }
                return "\"".concat(value.replace(/"/g, '""'), "\"");
            });
            csvRows.push(values.join(";"));
        });
        return csvRows.join("\n");
    };
    var prepareDownload = function () { return __awaiter(void 0, void 0, void 0, function () {
        var zip, timestamp, csvContentDot, csvContentComma, searchParamsJson, formattedText, readmeContent, blob, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (prevalenceData.length === 0)
                        return [2];
                    zip = new JSZip();
                    timestamp = getFormattedTimestamp();
                    csvContentDot = createCSVContent(prevalenceData, ".");
                    csvContentComma = createCSVContent(prevalenceData, ",");
                    zip.file("prevalence_data_dot_".concat(timestamp, ".csv"), csvContentDot);
                    zip.file("prevalence_data_comma_".concat(timestamp, ".csv"), csvContentComma);
                    searchParamsJson = JSON.stringify(searchParameters, null, 2);
                    formattedText = "Search Parameters - Generated on ".concat(timestamp, "\n\n").concat(searchParamsJson
                        .split("\n")
                        .map(function (line) {
                        if (line.includes("{"))
                            return line;
                        var keyMatch = line.match(/"(.*?)":/);
                        if (keyMatch) {
                            var key = keyMatch[1];
                            return "\n--- ".concat(key.toUpperCase(), " ---\n").concat(line);
                        }
                        return line;
                    })
                        .join("\n"));
                    readmeContent = (language === null || language === void 0 ? void 0 : language.toLowerCase()) === "de" ? GERMAN_README : ENGLISH_README;
                    zip.file("search_parameters_".concat(timestamp, ".txt"), formattedText);
                    zip.file("README_".concat(timestamp, ".txt"), readmeContent);
                    return [4, zip.generateAsync({ type: "blob" })];
                case 1:
                    blob = _a.sent();
                    url = window.URL.createObjectURL(blob);
                    setDownloadUrl(url);
                    setFilename("data_package_".concat(timestamp, ".zip"));
                    return [2];
            }
        });
    }); };
    useEffect(function () {
        if (prevalenceData.length > 0) {
            prepareDownload();
        }
    }, [prevalenceData, searchParameters]);
    var localeText = {
        noRowsLabel: t("dataGrid.noRowsLabel"),
        noResultsOverlayLabel: t("dataGridnoResultsOverlayLabel"),
        columnHeaderSortIconLabel: t("dataGridcolumnHeaderSortIconLabel"),
        columnMenuSortAsc: t("dataGridcolumnMenuSortAsc"),
        columnMenuSortDesc: t("dataGridcolumnMenuSortDesc"),
        columnMenuUnsort: t("dataGridcolumnMenuUnsort"),
        columnMenuHideColumn: t("dataGridcolumnMenuHideColumn"),
        columnMenuManageColumns: t("dataGridcolumnMenuManageColumns"),
    };
    var columns = [
        {
            field: "samplingYear",
            headerName: t("SAMPLING_YEAR"),
            minWidth: 130,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "microorganism",
            headerName: t("MICROORGANISM"),
            minWidth: 140,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
            renderCell: function (params) { return formatMicroorganismName(params.value); },
        },
        {
            field: "sampleOrigin",
            headerName: t("SAMPLE_ORIGIN"),
            minWidth: 140,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "samplingStage",
            headerName: t("SAMPLING_STAGE"),
            minWidth: 150,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "matrix",
            headerName: t("MATRIX"),
            minWidth: 120,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "numberOfSamples",
            headerName: t("NUMBER_OF_SAMPLES"),
            type: "number",
            minWidth: 170,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "numberOfPositive",
            headerName: t("NUMBER_OF_POSITIVE"),
            type: "number",
            minWidth: 150,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "percentageOfPositive",
            headerName: t("PERCENTAGE_OF_POSITIVE"),
            type: "number",
            valueGetter: function (value) { return "".concat(value.toFixed(2)); },
            minWidth: 150,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "ciMin",
            headerName: t("CIMIN"),
            type: "number",
            valueGetter: function (value) {
                return value != null ? value.toFixed(2) : "N/A";
            },
            minWidth: 150,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "ciMax",
            headerName: t("CIMAX"),
            type: "number",
            valueGetter: function (value) {
                return value != null ? value.toFixed(2) : "N/A";
            },
            minWidth: 130,
            flex: 1,
            headerClassName: "header-style",
            align: "left",
            headerAlign: "left",
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { marginBottom: "10px" } },
            React.createElement(DataGridControls, { heading: t("TABLE_DETAIL") })),
        React.createElement(ZNAccordion, { title: t("PREVALENCE_TABLE"), content: React.createElement("div", { style: {
                    height: 600,
                    width: "100%",
                    overflowX: "auto",
                    display: "flex",
                    flexDirection: "column",
                } },
                React.createElement(Typography, { variant: "subtitle1", style: {
                        marginBottom: "10px",
                        fontSize: "0.875rem",
                        color: theme.palette.text.secondary,
                    } },
                    t("Generated on"),
                    ":",
                    " ",
                    prevalenceUpdateDate || t("No date available")),
                React.createElement(ThemeProvider, { theme: localTooltipTheme },
                    React.createElement(DataGrid, { rows: prevalenceData, columns: columns, loading: loading, disableColumnFilter: true, hideFooter: false, localeText: localeText, sx: {
                            backgroundColor: "white",
                            border: 2,
                            borderColor: "primary.main",
                            "& .header-style": {
                                fontWeight: "bold",
                                whiteSpace: "normal !important",
                                wordWrap: "break-word !important",
                                fontSize: "1rem",
                                textAlign: "center",
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.primary
                                    .contrastText,
                                border: "1px solid #e0e0e0",
                            },
                            "& .MuiDataGrid-root": {
                                borderWidth: "1px",
                                borderColor: "rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-cell": {
                                border: "1px solid #e0e0e0",
                                textAlign: "center",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                borderBottom: "1px solid #e0e0e0",
                                borderRight: "1px solid #e0e0e0",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid #e0e0e0",
                            },
                            "& .MuiDataGrid-iconButtonContainer:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                            },
                            "& .MuiDataGrid-menuIconButton:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                            },
                            "& .MuiDataGrid-sortIcon:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                color: "#ffffff",
                            },
                            "& .MuiDataGrid-menuIconButton": {
                                color: "#ffffff !important",
                            },
                            "& .MuiSvgIcon-root": {
                                color: "#ffffff",
                            },
                            "& .MuiTooltip-tooltip": {
                                backgroundColor: "#f0f0f0",
                                color: "#000000",
                                fontSize: "1rem",
                            },
                        } })),
                downloadUrl && (React.createElement(Button, { variant: "contained", color: "primary", style: {
                        margin: "0.5em",
                        backgroundColor: theme.palette.primary.main,
                    } },
                    React.createElement(Link, { href: downloadUrl, download: filename, style: {
                            width: "100%",
                            padding: "0.5em 1em",
                            color: "inherit",
                            textDecoration: "none",
                        } }, t("DOWNLOAD_ZIP_FILE"))))), defaultExpanded: true, centerContent: false, withTopBorder: false }),
        React.createElement("div", { style: { height: "10px" } }),
        React.createElement(ZNAccordion, { title: t("PREVALENCE_CHART"), content: React.createElement("div", { style: {
                    maxHeight: "950px",
                    width: "100%",
                    overflow: "hidden",
                    flexDirection: "column",
                } },
                React.createElement("div", { style: { height: "100%", width: "100%" } },
                    React.createElement(PrevalenceChart, null))), defaultExpanded: true, centerContent: false, withTopBorder: false })));
};
export { PrevalenceDataGrid };
